<!--  -->
<template>
  <div class="recruit">
    <div class="topHeader">
      <img class="logo" src="../../assets/img/recruit/logo.png" @click="goHome" />
    </div>
    <div class="piaochuang" @click="gourl"></div>
    <div class="conmain">
      <div class="mainImg mto">
        <img src="../../assets/img/recruit/0.png" class="bgImg" />
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/1.png" class="bgImg" />
        <div class="add" @click="gourl"></div>
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/2.png" class="bgImg" />
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/3.png" class="bgImg" />
        <div class="other">
          <img src="../../assets/img/recruit/w.png" />
        </div>
        <div class="goUrl" @click="gourl"></div>
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/4.png" class="bgImg" />
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/5.png" class="bgImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      preloadList: [require("../../assets/img/recruit/h.png"), require("../../assets/img/recruit/add_h.png"), require("../../assets/img/recruit/fc_h.png")],
    };
  },
  computed: {},
  created() {
    for (let i = 0; i < this.preloadList.length; i++) {
      this.preloadImage(this.preloadList[i]);
    }
  },
  methods: {
    gourl() {
      window.open("https://wj.qq.com/s2/17884449/f83f/");
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
    preloadImage(src) {
      const img = new Image();
      img.src = src;
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit {
  width: 100%;
  min-width: 1250px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  .topHeader {
    width: 100%;
    height: 50px;
    background: #000000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 9;
    .logo {
      margin-left: 34px;
      cursor: pointer;
    }
  }
  .piaochuang {
    width: 148px;
    height: 163px;
    background: url("../../assets/img/recruit/fc.png") no-repeat;
    cursor: pointer;
    position: absolute;
    right: 23px;
    bottom: 60px;
    z-index: 99;
    &:hover {
      background: url("../../assets/img/recruit/fc_h.png") no-repeat;
    }
  }
  .conmain {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    background: url("../../assets/img/recruit/bg.jpg") no-repeat center;
    height: 3577px;
    .mainImg {
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      &.mto {
        margin-top: 50px;
      }
      .bgImg {
        width: 1920px;
        position: relative;
        left: 50%;
        transform: translate(-50%);
      }
      .other {
        position: absolute;
        margin-left: 156px;
        left: 50%;
        top: 107px;
      }
      .goUrl {
        width: 257px;
        height: 77px;
        background: url("../../assets/img/recruit/d.png") no-repeat;
        position: absolute;
        margin-left: 157px;
        left: 50%;
        top: 264px;
        border-radius: 34px;
        cursor: pointer;
        &:hover {
          background: url("../../assets/img/recruit/h.png") no-repeat;
        }
      }
      .add {
        width: 117px;
        height: 34px;
        position: absolute;
        left: 50%;
        top: 153px;
        margin-left: -49px;
        background: url("../../assets/img/recruit/add.png") no-repeat;
        cursor: pointer;
        &:hover {
          background: url("../../assets/img/recruit/add_h.png") no-repeat;
        }
      }
    }
  }
}
</style>
