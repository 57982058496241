<!-- 招募 -->
<template>
  <pcPageVue v-if="!isMobileMain"></pcPageVue>
  <appPage v-else></appPage>
</template>

<script>
import pcPageVue from './pcPage.vue';
import appPage from './appPage.vue';
export default {
  name: "",
  components: {
    pcPageVue,
    appPage
  },
  data() {
    return {
      isMobileMain: false,
    };
  },
  created(){
    this.isMobileMain = this.isMobile()
    console.log(this.isMobileMain)
  },
  computed: {},
  methods: {

    // 判断是不是移动端
    isMobile() {
      const UA = window.navigator.userAgent.toLowerCase();
      let ios = UA.match(/iphone/i) == "iphone" || UA.match(/ipad/i) == "ipad" ? !0 : !1,
        adr = UA.match(/android/i) == "android" ? !0 : !1;
      return ios || adr;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
