<!--  -->
<template>
  <div class="recruit">
    <div class="topHeader">
      <img class="logo" src="../../assets/img/recruit/app/logo.png" @click="goHome" />
    </div>
    <div class="fiedBtn" @click="gourl"></div>

    <div class="conmain">
      <div class="mainImg mto">
        <img src="../../assets/img/recruit/app/1.png" class="bgImg" />
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/app/2.png" class="bgImg" />
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/app/3.png" class="bgImg" />
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/app/4.png" class="bgImg" />
        <div class="goUrl" @click="gourl"></div>
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/app/5.png" class="bgImg" />
      </div>
      <div class="mainImg">
        <img src="../../assets/img/recruit/app/6.png" class="bgImg" />
      </div>
    </div>
    <div class="fiedBtnBG"></div>
    <div class="kb"></div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.init();
    this.getRem();
  },
  computed: {},
  methods: {
    getRem() {
      (function (doc, win) {
        let docEl = doc.documentElement,
          resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
          recalc = function () {
            let clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= 750) {
              docEl.style.fontSize = "100px";
            } else {
              docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
            }
          };

        recalc();
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener("DOMContentLoaded", recalc, false);
      })(document, window);
    },
    init() {
      let app = document.querySelector("#app");
      app.style.minWidth = "100vw";
      app.style.width = "100vw";
      app.style.minHeight = "100vh";
      app.style.height = "auto";
      if (app.querySelectorAll(".__header")[0]) app.querySelectorAll(".__header")[0].style.display = "none";
      if (app.querySelectorAll(".header_block")[0]) app.querySelectorAll(".header_block")[0].style.display = "none";
      if (app.querySelectorAll(".footer")[0]) app.querySelectorAll(".footer")[0].style.display = "none";
      if (app.querySelectorAll(".box")[0]) app.querySelectorAll(".box")[0].style.minWidth = "100vw";
    },
    gourl() {
      window.open("https://wj.qq.com/s2/17884449/f83f/");
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit {
  width: 100%;
  height: 100%;
  display: flex;
  .topHeader {
    width: 100%;
    height: 0.8rem;
    background: #000000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 9;
    .logo {
      width: 2.14rem;
      margin-left: 0.2rem;
      cursor: pointer;
    }
  }
  .fiedBtn {
    position: fixed;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    width: 4.06rem;
    height: 0.99rem;
    background: url("../../assets/img/recruit/app/btn.png") no-repeat;
    border-radius: 0.34rem;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .conmain {
    width: 100%;
    margin: 0 auto;
    background: url("../../assets/img/recruit/app/bg.jpg") no-repeat center;
    height: 50.86rem;
    .mainImg {
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      &.mto{
        margin-top: 0.8rem;
      }
      .bgImg {
        width: 100%;
        position: relative;
        left: 50%;
        transform: translate(-50%);
      }
      .other {
        position: absolute;
        margin-left: 156px;
        left: 50%;
        top: 107px;
      }
      .goUrl {
        width: 3.06rem;
        height: 2.61rem;
        background: url("../../assets/img/recruit/app/w.png") no-repeat;
        position: absolute;
        left: 50%;
        background-size: 100% 100%;
        top: 1.15rem;
        margin-left: 0.06rem;
      }
    }
  }
  .fiedBtnBG {
    width: 100%;
    height: 0.84rem;
    background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    position: fixed;
    left: 0;
    bottom: 0.47rem;
  }
  .kb {
    width: 100%;
    height: 0.47rem;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
</style>
